import * as React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import Layout from '../components/layout'
import { StaticImage } from "gatsby-plugin-image";

const BlogPost = ({ data }) => {
  return (
    <Layout pageTitle={data.mdx.frontmatter.title}>
      <label style={{ "fontStyle":"italic", "fontSize":"smaller"}}>Posted: {data.mdx.frontmatter.date}</label>
      <div style={{"backgroundColor":"#fff"}}>
      <MDXRenderer>
        {data.mdx.body}
      </MDXRenderer>
      </div>
    </Layout>
  )
}
export const query = graphql`
  query ($id: String) {
    mdx(id: {eq: $id}) {
      frontmatter {
        title
        date       
      }
      body
    }
  }
`
export default BlogPost